import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./assets/scss/custom.scss";

Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.mixin({
  methods: {
    onSubmit: event => {
      event.preventDefault();
      return false;
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
