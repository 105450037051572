<template>
  <div class="fulfilling-basic-needs">
    <stepper-progress stepNumber="1"></stepper-progress>
    <h2><span class="circle-counter">1</span>Fulfilling Basic Needs</h2>
    <h3>Rationale</h3>
    <p>
      Digital innovations have the potential to contribute to the satisfaction
      of some of the most basic needs, including food, water, education, health
      care and nowadays access to the Internet itself.
    </p>
    <guiding-question
      questionId="help-outcome-fulfilling-basic-needs"
      question="Does the solution unfold its impact through this first mechanism? If so, how?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution cater to the target population’s basic
      needs?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/opportunity/assessment"
      next="/outcome/generating-additional-income"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "FulfillingBasicNeeds",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "outcome.fulfillingBasicNeeds.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.fulfillingBasicNeeds.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer: "The solution shows no concern for people’s basic needs.",
          },
          name: "The solution shows no concern for people’s basic needs.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution somewhat improves access to a basic good which had already been available to the majority of the local population.",
          },
          name:
            "The solution somewhat improves access to a basic good which had already been available to the majority of the local population.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution significantly improves access to a basic good for some part of the local population.",
          },
          name:
            "The solution significantly improves access to a basic good for some part of the local population.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution caters to a basic need, some minorities had previously been deprived from.",
          },
          name:
            "The solution caters to a basic need, some minorities had previously been deprived from.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution caters to one or more basic needs, significant numbers of people had previously been deprived from.",
          },
          name:
            "The solution caters to one or more basic needs, significant numbers of people had previously been deprived from.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution identify the satisfaction of (a) basic need(s) as its main or one of its main targets?",
        "How large is the share of the target population currently unable to meet the basic need to be addressed?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        outcome: { fulfillingBasicNeeds: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        outcome: { fulfillingBasicNeeds: { assessment: this.assessment } },
      });
    },
  },
};
</script>
