<template>
  <div class="traffic-light">
    <p class="text-uppercase">
      <strong>Hit the brakes! (&lt; 2.5)</strong><br />
      <strong>Take a pit stop! (2.5-4.0)</strong><br />
      <strong>Move up a gear! (&gt; 4.0)</strong>
    </p>
    <div class="traffic-light-chart my-4">
      <div class="progress traffic-bar">
        <div
          class="progress-bar"
          role="progressbar"
          v-if="progressTrafficValue"
          :style="progressTrafficValue"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span class="traffic-meter-circle">o</span>
        </div>
      </div>
      <div class="row">
        <b-col v-for="n in 5" :key="n">
          <span :class="['axis-' + n]">{{ n }}</span>
        </b-col>
      </div>
    </div>
    <div v-if="trafficValue" class="chart-result-text">
      <p>
        <strong>{{ trafficValue.title }}</strong
        ><br />
        {{ trafficValue.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrafficLight",
  data: function () {
    return {
      overallAssessmentResult:
        Math.round(this.$store.getters.getOverallAssessmentValue() * 10) / 10,
    };
  },
  computed: {
    trafficValue: function () {
      if (!this.overallAssessmentResult) {
        return null;
      }
      if (this.overallAssessmentResult < 2.5) {
        return {
          title: "Hit the brakes! (< 2.5)",
          description:
            "Your solution may well be cutting-edge, in the context of pro-poor development, however, it seems to be misplaced. Your support is better placed elsewhere!",
        };
      }
      if (
        this.overallAssessmentResult > 2.5 &&
        this.overallAssessmentResult < 4.0
      ) {
        return {
          title: "Take a pit stop! (2.5-4.0)",
          description:
            "With some careful tweaks here and there, your solution has some signif- icant potential to serve a pro-poor purpose. Turn to this report’s Policy Recommendations for inspiration.",
        };
      }
      if (this.overallAssessmentResult >= 4.0) {
        return {
          title: "Move up a gear! (>4.0)",
          description:
            "Congratulations, that is a direct hit right there! Gather your team and keep pushing your solution forward..",
        };
      }
      return null;
    },
    progressTrafficValue: function () {
      if (this.overallAssessmentResult) {
        let widthValue = (((this.overallAssessmentResult - 1) * 1) / 4) * 100;
        if (widthValue > 98) {
          widthValue = 98;
        }
        return { width: widthValue + "%" };
      }
      return false;
    },
  },
};
</script>