<template>
  <div id="app">
    <b-container>
      <navbar></navbar>
    </b-container>
    <b-container class="px-0 px-sm-3">
      <div class="content-box px-5 pt-5 pb-3 mt-md-5">
        <b-row>
          <b-col cols="12" md="8">
            <router-view />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr class="mt-5" />
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="4" md="2">
            <a
              href="https://www.poverty-inequality.com/impressum/"
              class="text-right text-uppercase d-block"
              >Impressum</a
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";

export default {
  components: { Navbar },
  name: "App",
};
</script>
