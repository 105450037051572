<template>
  <div class="help">
    <h1>
      <img src="../assets/img/info_blue.svg" alt="" />Thematic introduction and
      how to use the Pro-Poor Digitalisation Tool
    </h1>
    <p>
      While it is true that more than half of the world’s population has access
      to the internet as of 2020, 3.5 billion people worldwide do not share this
      privilege. With more than 90 percent of these ‘unconnected’ residing in
      the developing world, it seems that it is precisely those struggling to
      keep pace with the speed of globalisation that are at risk of getting lost
      along the trail of a digitalisation that promised to ‘leave no one
      behind’. As the deadline of the Agenda 2030 is approaching, the question
      of how to deliver on the Sustainable Development Goals (SDGs) 1 (‘no
      poverty’) and 10 (‘reduced inequalities’) not despite but because of the
      sweeping changes enabled by digitalisation and other challenges remain yet
      to be answered. Against this background, this reflection tool and its
      underlying report provides a comprehensive overview of the complex
      phenomenon that is digital innovation and its interaction with the
      multidimensional issues of poverty and inequality. Lying at the report’s
      heart, the Pro-Poor Digitalisation Tool integrates research-based findings
      into an action-oriented framework ready to guide and evaluate pro-poor
      digitalisation efforts of entrepreneurs, politicians, and development
      actors alike. A lot has been done – a lot is left to do:
    </p>
    <h2>Step 1: Reflecting upon the Underlying Need</h2>
    <p>
      The world is connected – but some are not. That is why “starting with the
      people – not the technology” is one of the most important rules when
      designing digital solutions. Therefore, the Pro-Poor Digitalisation Tool
      internalizes this principle and starts with a reflecting process. Guided
      by three questions in the first section, it is important to reflect upon
      the envisioned group of beneficiaries, their needs and the means by which
      your digital solution aims to serve those needs.
    </p>
    <h2>Step 2: Assessing ‘Creation – Opportunity – Outcome’</h2>
    <p>
      The pro-poor digitalisation tool embrace and further refine a holistic
      approach by identifying three equally valid dimensions of pro-poor
      digitalisation, comprising five sub-dimensions each.<br />
      First, <strong>Creation</strong> addresses the question how your digital
      solution is produced and delivered and shines a light on the underlying
      market structure, capacity building, data ownership and security as well
      as the solution providers’ accountability.<br />
      Second, <strong>Opportunity</strong> assesses how a given solution is
      accessed and used. In order to assess the divergence in opportunities
      determining these factors, it focuses on issues of availability,
      affordability, awareness, abilities and agency.<br />
      Acknowledging that different types of innovation yield different societal
      and economic impacts, the third dimension, <strong>Outcome</strong>,
      investigates whether and – if so – how your digital solution unfolds its
      leverage effect. In reference to the most common theoretical foundations,
      the underlying report identified the fulfilment of basic needs, generation
      of additional income, enhancement of people’s agency, reduction of
      vulnerabilities as well as natural resource conservation as five ways for
      digital solutions to create pro-poor impact.
    </p>
    <h2>Step 3: Concluding Reflection</h2>
    <p>
      Having analyzed a digital innovation or solution and its potential for
      pro-poor impact along the three distinct dimensions, step 3 provides room
      to reflect upon some overarching questions. This aims at not only
      considering potentially negative impacts but also to dissect (structural)
      barriers standing in the way of the digital solution’s successful
      implementation and at identifying measures for further improvement.
    </p>
    <stepper-buttons prev="/" next="/need"></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";

export default {
  name: "Info",
  components: { StepperButtons },
};
</script>