<template>
  <div class="step-opportunity">
    <h1><img src="../assets/img/atom_blue.svg" alt="" />Opportunity</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "StepOpportunity",
};
</script>
