<template>
  <div class="stepper-progress my-4">
    <b-row>
      <b-col cols="12" md="6">
        <b-progress :value="stepNumber" :max="totalSteps"></b-progress>
        <div>
          <small class="text-uppercase text-muted">
            <span class="text-uppercase"
              >Step {{ stepNumber }} out of {{ totalSteps }}
            </span>
            <span class="text-uppercase float-right">{{ percentage }} %</span>
          </small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "StepperProgress",
  props: {
    stepNumber: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      totalSteps: 6,
    };
  },
  computed: {
    percentage: function () {
      return Math.ceil((this.stepNumber / this.totalSteps) * 100);
    },
  },
};
</script>