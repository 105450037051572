<template>
  <div :id="chartIdData" class="chart-wrapper"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "RadarChart",
  props: {
    chartId: {
      type: String,
      required: true,
    },
    chartModel: {
      type: Array,
      required: false,
    },
  },
  data: function () {
    return {
      chart: null,
      chartIdData: this.chartId,
      chartData: this.chartModel,
    };
  },
  methods: {
    getFormData: function (path) {
      return this.$store.getters.getFormDataByPath(path);
    },
  },
  mounted() {
    this.chart = am4core.create(this.chartIdData, am4charts.RadarChart);

    this.chart.paddingRight = 20;

    this.chart.data = this.chartData;

    /* Create axes */
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "topic";

    this.chart.yAxes.push(new am4charts.ValueAxis());

    /* Create and configure series */
    let series = this.chart.series.push(new am4charts.RadarSeries());
    series.dataFields.valueY = "score";
    series.dataFields.categoryX = "topic";
    series.name = "Score";
    series.strokeWidth = 5;
    series.stroke = am4core.color("#00689D");

    // this.chart = chart;
    // this.chart = this.chartData;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>