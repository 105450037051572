<template>
  <div class="overview">
    <h1>{{ getFormData("home.name") }}</h1>
    <h1>
      <img src="../../assets/img/stats_blue.svg" alt="stats" />Underlying need
    </h1>
    <p>Who is the target user of the digital solution?</p>
    <p class="font-italic text-muted">{{ getFormData("need.target") }}</p>
    <p>What particular user need does the solution address?</p>
    <p class="font-italic text-muted">{{ getFormData("need.user") }}</p>
    <p>How does the solution tackle these needs?</p>
    <p class="font-italic text-muted">{{ getFormData("need.tackle") }}</p>
    <hr class="my-4" />
    <h1><img src="../../assets/img/bulb_blue.svg" alt="bulb" />Creation</h1>
    <result-item
      title="Market Structure"
      stepCounter="1"
      guidingQuestion="Who is providing the solution and how is the solution provider positioned in the market?"
      assessmentQuestion="To what extent is the solution the product of or basis for exploitation of market power?"
      formPath="creation.marketStructure"
      :additionalRessources="additionalRessources.creation.marketStructure"
    ></result-item>
    <result-item
      title="Capacity Building"
      stepCounter="2"
      guidingQuestion="Who is developing the solution? Is capacity building an integral part of the process?"
      assessmentQuestion="Does the solution allow for upward mobility of local production along global value chains?"
      formPath="creation.capacityBuilding"
      :additionalRessources="additionalRessources.creation.capacityBuilding"
    ></result-item>
    <result-item
      title="Data Ownership"
      stepCounter="3"
      guidingQuestion="Who owns the underlying data and who has access on what terms?"
      assessmentQuestion="To what extent does the solution allow its users to control their data and how its being used?"
      formPath="creation.dataOwnership"
      :additionalRessources="additionalRessources.creation.dataOwnership"
    ></result-item>
    <result-item
      title="Data Security"
      stepCounter="4"
      guidingQuestion="How is personal data stored and transmitted?"
      assessmentQuestion="To what extent does the solution take matters of data security into account and deploy pre-emptive measures?"
      formPath="creation.dataSecurity"
      :additionalRessources="additionalRessources.creation.dataSecurity"
    ></result-item>
    <result-item
      title="Accountability"
      stepCounter="5"
      guidingQuestion="How is accountability and transparency to local politics and civil society ensured?"
      assessmentQuestion="To what extent is the solution provider transparent and accountable to users, governments and other stakeholders?"
      formPath="creation.accountability"
      :additionalRessources="additionalRessources.creation.accountability"
    ></result-item>
    <h4><span class="circle-counter">6</span>Assessment</h4>
    <p>
      To which extent does the solution fulfil the 5 criteria addressed in the
      questions before?
    </p>
    <radar-chart
      chartId="chart-creation-result"
      :chartModel="chartDataCreation"
      class="my-4"
    ></radar-chart>

    <hr class="my-4" />
    <h1><img src="../../assets/img/atom_blue.svg" alt="atom" />Opportunity</h1>
    <result-item
      title="Availability"
      stepCounter="1"
      guidingQuestion="Do all people in the target region fulfil the solution’s technical requirements?"
      assessmentQuestion="To what extent is physical access to indispensable infrastructure and thus the solution itself provided?"
      formPath="opportunity.availability"
      :additionalRessources="additionalRessources.opportunity.availability"
    ></result-item>
    <result-item
      title="Affordability"
      stepCounter="2"
      guidingQuestion="Can everyone afford the service?"
      assessmentQuestion="To what extent is access to indispensable infrastructure and thus the solution itself affordable for everyone in the target population?"
      formPath="opportunity.affordability"
      :additionalRessources="additionalRessources.opportunity.affordability"
    ></result-item>
    <result-item
      title="Awareness"
      stepCounter="3"
      guidingQuestion="Are the solution and its potential functions recognised within the target group?"
      assessmentQuestion="To what extent is information about the solution and its problem-solving capacity accessible to everyone in the target population?"
      formPath="opportunity.awareness"
    ></result-item>
    <result-item
      title="Abilities"
      stepCounter="4"
      guidingQuestion="Does the solution account for the (physical & cognitive) capabilities of all potential users?"
      assessmentQuestion="To what extent is the solution usable, accessible and comprehensible to everyone in the target population?"
      formPath="opportunity.abilities"
      :additionalRessources="additionalRessources.opportunity.abilities"
    ></result-item>
    <result-item
      title="Agency"
      stepCounter="5"
      guidingQuestion="Do people have the freedom and the opportunity to make informed choices about the solutions (non-) use?"
      assessmentQuestion="To what extent can people make informed choices about the use or non-use of the solution?"
      formPath="opportunity.agency"
    ></result-item>
    <h4><span class="circle-counter">6</span>Assessment</h4>
    <p>
      To which extent does the solution fulfil the 5 criteria addressed in the
      questions before?
    </p>
    <radar-chart
      chartId="chart-opportunity-result"
      :chartModel="chartDataOpportunity"
      class="my-4"
    ></radar-chart>

    <hr class="my-4" />
    <h1>
      <img src="../../assets/img/megaphone_blue.svg" alt="megaphone" />Outcome
    </h1>
    <result-item
      title="Fulfilling Basic Needs"
      stepCounter="1"
      guidingQuestion="Does the solution unfold its impact through this first mechanism? If so, how?"
      assessmentQuestion="To what extent does the solution cater to the target population’s basic
      needs?"
      formPath="outcome.fulfillingBasicNeeds"
    ></result-item>
    <result-item
      title="Generating Additional Income"
      stepCounter="2"
      guidingQuestion="Does the solution unfold its impact through this second mechanism? If so, how?"
      assessmentQuestion="To what extent does the solution allow for additional income generation
      beyond the original business idea?"
      formPath="outcome.generatingAdditionalIncome"
      :additionalRessources="additionalRessources.outcome.generatingAdditionalIncome"
    ></result-item>
    <result-item
      title="Enhancing People’s Agency"
      stepCounter="3"
      guidingQuestion="Does the solution unfold its impact through this third mechanism? If so, how?"
      assessmentQuestion="To what extent does the solution build up the target population’s social,
      economic or political agency?"
      formPath="outcome.enhancingPeoplesAgency"
    ></result-item>
    <result-item
      title="Reducing Vulnerability"
      stepCounter="4"
      guidingQuestion="Does the solution unfold its impact through this fourth mechanism? If so, how?"
      assessmentQuestion="To what extent does the solution mitigate or help manage the specific
      risks faced by the target population?"
      formPath="outcome.reducingVulnerability"
    ></result-item>
    <result-item
      title="Conserving Natural Resource Base"
      stepCounter="5"
      guidingQuestion="Does the solution unfold its impact through this fifth mechanism? If so, how?"
      assessmentQuestion="To what extent does the solution work to conserve or replenish natural
      resources critical to the target population’s well-being?"
      formPath="outcome.conservingNaturalResourceBase"
    ></result-item>

    <hr class="my-4" />
    <h1>
      <img src="../../assets/img/comment_blue.svg" alt="comment" />Concluding
      Reflection
    </h1>
    <p>Which societal groups will particularly benefit from the solution?</p>
    <p class="font-italic text-muted">
      {{ getFormData("reflection.benefit") }}
    </p>
    <p>
      Which societal groups will potentially lose out? Might the solution cause
      new inequalities?
    </p>
    <p class="font-italic text-muted">{{ getFormData("reflection.lose") }}</p>
    <p>Which hurdles need to be overcome to maximise the solution‘s impact?</p>
    <p class="font-italic text-muted">
      {{ getFormData("reflection.hurdles") }}
    </p>
    <p>Which concrete measures can be taken?</p>
    <p class="font-italic text-muted">
      {{ getFormData("reflection.measures") }}
    </p>
    <hr class="my-4" />
  </div>
</template>

<script>
import ResultItem from "@/components/ResultItem.vue";
import RadarChart from "@/components/RadarChart.vue";

export default {
  name: "Overview",
  components: { ResultItem, RadarChart },
  data: function () {
    return {
      chart: null,
      chartDataCreation: [
        {
          topic: "Market Structure",
          score: this.getFormData("creation.marketStructure.assessment.value"),
        },
        {
          topic: "Capacity building",
          score: this.getFormData("creation.capacityBuilding.assessment.value"),
        },
        {
          topic: "Data ownership",
          score: this.getFormData("creation.dataOwnership.assessment.value"),
        },
        {
          topic: "Data security",
          score: this.getFormData("creation.dataSecurity.assessment.value"),
        },
        {
          topic: "Accountabilty",
          score: this.getFormData("creation.accountability.assessment.value"),
        },
      ],
      chartDataOpportunity: [
        {
          topic: "Availability",
          score: this.getFormData("opportunity.availability.assessment.value"),
        },
        {
          topic: "Affordability",
          score: this.getFormData("opportunity.affordability.assessment.value"),
        },
        {
          topic: "Awareness",
          score: this.getFormData("opportunity.awareness.assessment.value"),
        },
        {
          topic: "Abilities",
          score: this.getFormData("opportunity.abilities.assessment.value"),
        },
        {
          topic: "Agency",
          score: this.getFormData("opportunity.agency.assessment.value"),
        },
      ],
      additionalRessources: {
        creation: {
          marketStructure: [
            {
              name:
                "BMZ (2018): Toolkit Digitalisierung. Open Source – Nutzung und Entwicklung freier Software. (only available in German)",
              url:
                "https://toolkit-digitalisierung.de/praxis/konzeption-und-entwicklung/open-source/",
            },
            {
              name:
                "UNCTAD (2013): Promoting Local IT Sector Development through Public Procurement.",
              url:
                "https://unctad.org/en/PublicationsLibrary/dtlstict2012d5_en.pdf",
            },
          ],
          capacityBuilding: [
            {
              name:
                "BMZ (2018): Toolkit Digitalisierung. Open Source – Nutzung und Entwicklung freier Software. (only available in German)",
              url:
                "https://toolkit-digitalisierung.de/wissen/lokale-innovationen/tech-start-up-foerderung/",
            },
          ],
          dataOwnership: [
            {
              name:
                "BMZ (2018): Toolkit Digitalisierung. Offene Daten. (only available in German)",
              url:
                "https://toolkit-digitalisierung.de/praxis/implementierung/offene-daten-transparente-regierung-gemeinsames-wissen/",
            },
            {
              name:
                "Open Data for Development",
              url:
                "https://www.od4d.net/",
            },
            {
              name:
                "Open Knowledge Foundation (2020): Open Data Handbook.",
              url:
                "http://opendatahandbook.org/",
            },
          ],
          dataSecurity: [
            {
              name:
                "GDPR Checklist",
              url:
                "https://gdpr.eu/checklist/",
            },
            {
              name:
                "GIZ (2018): Responsible Data Guidelines.",
              url:
                "https://mia.giz.de/qlink/ID=245420000",
            },
            {
              name:
                "GIZ (2018): Responsible Data Guidelines – Toolbox.",
              url:
                "https://mia.giz.de/qlink/ID=245422000",
            },
            {
              name:
                "ICRC (2017): Handbook on Data Protection in Humanitarian Action.",
              url:
                "https://www.icrc.org/en/publication/handbook-data-protection-humanitarian-action",
            },
            {
              name:
                "Open Data Institute (2019): Data Ethics Canvas",
              url:
                "https://theodi.org/article/data-ethics-canvas/",
            },
            {
              name:
                "UN OCHA (2019): Data Responsibility Guidelines.",
              url:
                "https://centre.humdata.org/wp-content/uploads/2019/03/OCHA-DR-Guidelines-working-draft-032019.pdf",
            },
          ],
          accountability: [
            {
              name:
                "Stanford Center of Philanthropy and Civil Society (2020): Integrated Advocacy. Paths forward for Digital Civil Society.",
              url:
                "https://pacscenter.stanford.edu/publication/integrated-advocacy-paths-forward-for-digital-civil-society/",
            },
          ],
        },
        opportunity: {
          availability: [
            {
              name:
                "Broadband Commission (2019): Connecting Africa through Broadband. A Strategy for Doubling Connectivity by 2021 and Reaching Universal Access by 2030.",
              url:
                "https://www.broadbandcommission.org/Documents/working-groups/DigitalMoonshotforAfrica_Report.pdf",
            },
            {
              name:
                "Fraunhofer FIT (2019): Connecting the Unconnected. Tackling the Challenge of Cost-Effective Broadband Internet in Rural Areas.",
              url:
                "https://toolkit-digitalisierung.de/app/uploads/2019/10/Connecting-the-Unconnected-by-Fraunhofer-FIT-20191009-1.pdf",
            },
            {
              name:
                "OECD (2018): Bridging the Rural Digital Divide.",
              url:
                "https://www.oecd-ilibrary.org/science-and-technology/bridging-the-rural-digital-divide_852bd3b9-en",
            },
          ],
          affordability: [
            {
              name:
                "Alliance for Affordable Internet (A4AI)",
              url:
                "https://a4ai.org/",
            },
          ],
          abilities: [
            {
              name:
                "#eSkills4Girls: World Map on Digital Skills Trainings for Women & Girls.",
              url:
                "https://www.eskills4girls.org/map-full/",
            },
            {
              name:
                "ITU (2018): Digital Skills Toolkit.",
              url:
                "https://www.itu.int/en/ITU-D/Digital-Inclusion/Documents/ITU%20Digital%20Skills%20Toolkit.pdf",
            },
            {
              name:
                "OECD (2019): OECD Skills Outlook 2019. Thriving in a Digital World.",
              url:
                "https://www.oecd-ilibrary.org/education/oecd-skills-outlook-2019_df80bc12-en;jsessionid=MTC3hJwKTMx3dMwxyZm1r3mp.ip-10-240-5-167",
            },
            {
              name:
                "User Experience Testing",
              url:
                "https://www.ueq-online.org/",
            },
            {
              name:
                "Web Content Accessibility Guidelines",
              url:
                "https://www.w3.org/TR/WCAG21/",
            },
            {
              name:
                "Web Content Accessibility Quick Check",
              url:
                "https://www.w3.org/WAI/test-evaluate/preliminary/",
            },
          ],
        },
        outcome:{
          generatingAdditionalIncome: [
            {
              name:
                "Fairwork Foundation: Fairwork Platform Ratings.",
              url:
                "https://fair.work/ratings/",
            },
          ],
        },
      },
    };
  },
  computed: {},
  methods: {
    getFormData: function (path) {
      return this.$store.getters.getFormDataByPath(path);
    },
  },
};
</script>
