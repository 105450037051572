<template>
  <div class="generating-additional-income">
    <stepper-progress stepNumber="2"></stepper-progress>
    <h2><span class="circle-counter">2</span>Generating Additional Income</h2>
    <h3>Rationale</h3>
    <p>
      Digital innovations can open up business and entrepreneurship
      opportunities which did not exist before, hereby generating additional
      income and/or creating jobs. Examples encompass new distribution channels
      through e-commerce platforms or micro-work in the gig economy.
    </p>
    <guiding-question
      questionId="help-outcome-generating-additional-income"
      question="Does the solution unfold its impact through this second mechanism? If so, how?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution allow for additional income generation
      beyond the original business idea?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/outcome"
      next="/outcome/enhancing-peoples-agency"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "GeneratingAdditionalIncome",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "outcome.generatingAdditionalIncome.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.generatingAdditionalIncome.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution does not create any additional opportunities for income generation.",
          },
          name:
            "The solution does not create any additional opportunities for income generation.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution creates additional opportunities for income generation among advantaged and/or middle class individuals.",
          },
          name:
            "The solution creates additional opportunities for income generation among advantaged and/or middle class individuals.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution creates some additional opportunities for income generation, including among marginalised target groups.",
          },
          name:
            "The solution creates some additional opportunities for income generation, including among marginalised target groups.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution creates additional income opportunities on a larger scale. They are particularly relevant and accessible to marginalised target communities",
          },
          name:
            "The solution creates additional income opportunities on a larger scale. They are particularly relevant and accessible to marginalised target communities",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution creates target group-sensitive income opportunities on a larger scale. Positive spillovers to different segments of the local economy can be observed.",
          },
          name:
            "The solution creates target group-sensitive income opportunities on a larger scale. Positive spillovers to different segments of the local economy can be observed.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution bear potential for additional income generation? Through which means?",
        "If the solution allows for the creation of additional jobs, of which nature are these jobs and which segments of society might they be available to respectively?",
        "Are certain labour standards ensured? If so, how?",
        "Is there potential for spill-over effects, e.g. by tapping new sales markets for local products?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        outcome: { generatingAdditionalIncome: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        outcome: {
          generatingAdditionalIncome: { assessment: this.assessment },
        },
      });
    },
  },
};
</script>
