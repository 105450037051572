<template>
  <div class="stepperbuttons mb-2">
    <b-button
      v-show="prev"
      :to="prev"
      variant="outline-primary"
      class="mr-3 d-print-none"
      >Back</b-button
    >
    <b-button
      v-show="next"
      :to="next"
      variant="outline-primary"
      class="d-print-none"
      >Next</b-button
    >
  </div>
</template>

<script>
export default {
  name: "StepperButtons",
  props: {
    prev: {
      type: String,
      required: false,
    },
    next: {
      type: String,
      required: false,
    },
  },
};
</script>