<template>
  <div class="enhancing-peoples-agency">
    <stepper-progress stepNumber="3"></stepper-progress>
    <h2><span class="circle-counter">3</span>Enhancing People’s Agency</h2>
    <h3>Rationale</h3>
    <p>
      Sen’s idea of ‘development as freedom’ suggests moving beyond a merely
      materialistic view. Against this background, a digital solution can be
      assessed based on its ability to enhance people’s agency and facilitate
      their political and social inclusion.
    </p>
    <guiding-question
      questionId="help-outcome-enhancing-peoples-agency"
      question="Does the solution unfold its impact through this third mechanism? If so, how?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution build up the target population’s social,
      economic or political agency?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/outcome/generating-additional-income"
      next="/outcome/reducing-vulnerability"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "EnhancingPeoplesAgency",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "outcome.enhancingPeoplesAgency.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.enhancingPeoplesAgency.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution does not carry any agency-enhancing features.",
          },
          name: "The solution does not carry any agency-enhancing features.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution strengthens people’s agency in at least one realm. However, it is especially dominant societal groups who benefit.",
          },
          name:
            "The solution strengthens people’s agency in at least one realm. However, it is especially dominant societal groups who benefit.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution strengthens people’s agency throughout different realms, also benefitting marginalised groups.",
          },
          name:
            "The solution strengthens people’s agency throughout different realms, also benefitting marginalised groups.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution strengthens people’s agency in at least one realm, particularly benefitting marginalised groups.",
          },
          name:
            "The solution strengthens people’s agency in at least one realm, particularly benefitting marginalised groups.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution enhances people’s agency throughout different realms. Previously marginalised groups are especially empowered at a large scale.",
          },
          name:
            "The solution enhances people’s agency throughout different realms. Previously marginalised groups are especially empowered at a large scale.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution enhance people’s ability to shape their own destiny, e.g. by improving access to financial services thus empowering them economically?",
        "Does the solution facilitate social inclusion of formerly estranged groups?",
        "Does the solution improve people’s ability to claim and exercise their right to political participation?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        outcome: { enhancingPeoplesAgency: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { enhancingPeoplesAgency: { assessment: this.assessment } },
      });
    },
  },
};
</script>
