<template>
  <div class="agency">
    <stepper-progress stepNumber="5"></stepper-progress>
    <h2><span class="circle-counter">5</span>Agency</h2>
    <h3>Rationale</h3>
    <p>
      Being an active agent of change rather than a passive recipient of
      external support lies at the heart of people’s empowerment. To exercise
      agency, people must be endowed with both freedom and opportunity to make
      informed choices about the use or non-use of digital solutions.
    </p>
    <guiding-question
      questionId="help-opportunity-agency"
      question="Do people have the freedom and the opportunity to make informed choices
      about the solutions (non-) use?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent can people make informed choices about the use or non-use
      of the solution?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/opportunity/abilities"
      next="/opportunity/assessment"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "Agency",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "opportunity.agency.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "opportunity.agency.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer: "Freedom to choose (non-) use.",
          },
          name: "Freedom to choose (non-) use.",
        },
        {
          item: {
            value: 2,
            answer: "Existence of viable alternatives.",
          },
          name: "Existence of viable alternatives.",
        },
        {
          item: {
            value: 3,
            answer:
              "Mechanisms to assess quality are in place, e.g. regular reports, open discussion.",
          },
          name:
            "Mechanisms to assess quality are in place, e.g. regular reports, open discussion.",
        },
        {
          item: {
            value: 4,
            answer:
              "Users have the possibility to contact customer support and file complaints",
          },
          name:
            "Users have the possibility to contact customer support and file complaints",
        },
        {
          item: {
            value: 5,
            answer:
              "Users can connect to other users to exchange about the service.",
          },
          name:
            "Users can connect to other users to exchange about the service.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Do viable alternatives exist?",
        "Do users have the capability to assess the solution’s quality and value for money?",
        "Do users know where and how to address any issues they might have with the solution?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        opportunity: { agency: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        opportunity: { agency: { assessment: this.assessment } },
      });
    },
  },
};
</script>
