<template>
  <div class="assessment-result d-print-none">
    <h1>Overall Assessment</h1>
    <p>
      The traffic light system displayed below will help you to decide where to
      move up a gear – and where to hit the brakes instead.
    </p>
    <traffic-light></traffic-light>
  </div>
</template>

<script>
import TrafficLight from "@/components/TrafficLight.vue";

export default {
  name: "AssessmentResult",
  components: { TrafficLight },
};
</script>
