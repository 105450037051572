import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home.vue";
import StepNeed from "../components/StepNeed.vue";
import StepCreation from "../components/StepCreation.vue";
import StepOpportunity from "../components/StepOpportunity.vue";
import StepOutcome from "../components/StepOutcome.vue";
import StepReflection from "../components/StepReflection.vue";
import StepResult from "../components/StepResult.vue";
import MarketStructure from "../components/creation/MarketStructure.vue";
import CapacityBuilding from "../components/creation/CapacityBuilding.vue";
import DataOwnership from "../components/creation/DataOwnership.vue";
import DataSecurity from "../components/creation/DataSecurity.vue";
import Accountability from "../components/creation/Accountability.vue";
import AssessmentCreation from "../components/creation/AssessmentCreation.vue";
import Availability from "../components/opportunity/Availability.vue";
import Affordability from "../components/opportunity/Affordability.vue";
import Awareness from "../components/opportunity/Awareness.vue";
import Abilities from "../components/opportunity/Abilities.vue";
import Agency from "../components/opportunity/Agency.vue";
import AssessmentOpportunity from "../components/opportunity/AssessmentOpportunity.vue";
import FulfillingBasicNeeds from "../components/outcome/FulfillingBasicNeeds.vue";
import GeneratingAdditionalIncome from "../components/outcome/GeneratingAdditionalIncome.vue";
import EnhancingPeoplesAgency from "../components/outcome/EnhancingPeoplesAgency.vue";
import ReducingVulnerability from "../components/outcome/ReducingVulnerability.vue";
import ConservingNaturalResourceBase from "../components/outcome/ConservingNaturalResourceBase.vue";
import AssessmentOutcome from "../components/outcome/AssessmentOutcome.vue";
import Download from "../components/Download.vue";
import Info from "../components/Info.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/need",
    name: "Need",
    component: StepNeed
  },
  {
    path: "/creation",
    name: "Creation",
    component: StepCreation,
    children: [
      { path: "", component: MarketStructure },
      { path: "capacity-building", component: CapacityBuilding },
      { path: "data-ownership", component: DataOwnership },
      { path: "data-security", component: DataSecurity },
      { path: "accountability", component: Accountability },
      { path: "assessment", component: AssessmentCreation }
    ]
  },
  {
    path: "/opportunity",
    name: "Opportunity",
    component: StepOpportunity,
    children: [
      { path: "", component: Availability },
      { path: "affordability", component: Affordability },
      { path: "awareness", component: Awareness },
      { path: "abilities", component: Abilities },
      { path: "agency", component: Agency },
      { path: "assessment", component: AssessmentOpportunity }
    ]
  },
  {
    path: "/outcome",
    name: "Outcome",
    component: StepOutcome,
    children: [
      { path: "", component: FulfillingBasicNeeds },
      {
        path: "generating-additional-income",
        component: GeneratingAdditionalIncome
      },
      { path: "enhancing-peoples-agency", component: EnhancingPeoplesAgency },
      { path: "reducing-vulnerability", component: ReducingVulnerability },
      {
        path: "conserving-natural-resource-base",
        component: ConservingNaturalResourceBase
      },
      { path: "assessment", component: AssessmentOutcome }
    ]
  },
  {
    path: "/reflection",
    name: "Reflection",
    component: StepReflection
  },
  {
    path: "/results",
    name: "Result",
    component: StepResult
  },
  {
    path: "/download",
    name: "Download",
    component: Download
  },
  {
    path: "/info",
    name: "Info",
    component: Info
  }
];

const router = new VueRouter({
  routes
});

export default router;
