<template>
  <div class="reducing-vulnerability">
    <stepper-progress stepNumber="4"></stepper-progress>
    <h2><span class="circle-counter">4</span>Reducing Vulnerability</h2>
    <h3>Rationale</h3>
    <p>
      Daily life in developing countries is often inherently risky for the poor
      (e.g. crop failures, natural disasters, epidemics, conflict). Digital
      solutions can not only provide information about potential shocks and
      facilitate traditional ways of reducing risk through kinship networks but
      also enable new ways of safeguarding, e.g. through micro-insurances.
    </p>
    <guiding-question
      questionId="help-outcome-reducing-vulnerability"
      question="Does the solution unfold its impact through this fourth mechanism? If so, how?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution mitigate or help manage the specific
      risks faced by the target population?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/outcome/enhancing-peoples-agency"
      next="/outcome/conserving-natural-resource-base"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "ReducingVulnerability",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "outcome.reducingVulnerability.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.reducingVulnerability.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution addresses a risk somewhat relevant to the local setting.",
          },
          name:
            "The solution addresses a risk somewhat relevant to the local setting.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution addresses a risk particularly relevant to the local setting.",
          },
          name:
            "The solution addresses a risk particularly relevant to the local setting.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution addresses a risk disproportionately affecting marginalised target groups.",
          },
          name:
            "The solution addresses a risk disproportionately affecting marginalised target groups.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution helps manage the consequences of any such risk.",
          },
          name: "The solution helps manage the consequences of any such risk.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution helps to both manage consequences and limit the scope of any given disaster in the first place.",
          },
          name:
            "The solution helps to both manage consequences and limit the scope of any given disaster in the first place.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "How relevant is the risk to be mitigated to the local context?",
        "Are there any societal groups that are affected disproportionately by the risk? Which are those?",
        "Does the solution significantly reduce said risk? If so, how?",
        "Does the solution aim to mitigate the risk itself or rather manage a given shock’s consequences?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        outcome: { reducingVulnerability: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        outcome: {
          reducingVulnerability: { assessment: this.assessment },
        },
      });
    },
  },
};
</script>
