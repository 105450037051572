<template>
  <div id="nav">
    <b-navbar toggleable="lg" class="px-0">
      <b-navbar-brand to="/" class="mr-5"
        ><span class="d-block">PRO POOR</span
        ><span class="d-block">DIGITALISATION</span
        ><span class="d-block">TOOL</span></b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            to="/need"
            :class="[$route.path == '/need' ? 'active' : '']"
            >Need</b-nav-item
          >
          <b-nav-item
            to="/creation"
            :class="[$route.path == '/creation' ? 'active' : '']"
            >Creation</b-nav-item
          >
          <b-nav-item
            to="/opportunity"
            :class="[$route.path == '/opportunity' ? 'active' : '']"
            >Opportunity</b-nav-item
          >
          <b-nav-item
            to="/outcome"
            :class="[$route.path == '/outcome' ? 'active' : '']"
            >Outcome</b-nav-item
          >
          <b-nav-item
            to="/reflection"
            :class="[$route.path == '/reflection' ? 'active' : '']"
            >Reflection</b-nav-item
          >
          <b-nav-item
            to="/results"
            :class="[$route.path == '/results' ? 'active' : '']"
            >Results</b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            to="/download"
            :class="[$route.path == '/download' ? 'active' : '']"
            ><img alt="Download" src="../assets/img/download.svg" />
          </b-nav-item>
          <b-nav-item href="mailto:poverty-inequality@giz.de"
            ><img alt="Mail" src="../assets/img/mail.svg"
          /></b-nav-item>
          <b-nav-item
            to="/info"
            :class="[$route.path == '/info' ? 'active' : '']"
            ><img alt="Info" src="../assets/img/info_white.svg"
          /></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
