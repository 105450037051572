<template>
  <div class="data-ownership">
    <stepper-progress stepNumber="3"></stepper-progress>
    <h2><span class="circle-counter">3</span>Data Ownership</h2>
    <h3>Rationale</h3>
    <p>
      Data is the key economic resource of the 21st century. Having control over
      (and the ability to potentially monetise) data is a source of political,
      social and economic power.
    </p>
    <guiding-question
      questionId="help-creation-data-ownership"
      question="Who owns the underlying data and who has access on what terms?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution allow its users to control their data and
      how its being used?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation/capacity-building"
      next="/creation/data-security"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "DataOwnership",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "creation.dataOwnership.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "creation.dataOwnership.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "Data is controlled by solution provider or third party. Easy accessible information (reflecting user’s capabilities) about its use is not provided.",
          },
          name:
            "Data is controlled by solution provider or third party. Easy accessible information (reflecting user’s capabilities) about its use is not provided.",
        },
        {
          item: {
            value: 2,
            answer:
              "Data is controlled by solution provider or third party. Despite accessible information being available, users can hardly determine their data’s use.",
          },
          name:
            "Data is controlled by solution provider or third party. Despite accessible information being available, users can hardly determine their data’s use.",
        },
        {
          item: {
            value: 3,
            answer:
              "Data is controlled by solution provider or third party. Users can make informed decision about its use.",
          },
          name:
            "Data is controlled by solution provider or third party. Users can make informed decision about its use.",
        },
        {
          item: {
            value: 4,
            answer:
              "Data is open (where applicable) or fully controlled by users. However, users do not have the means to exploit it.",
          },
          name:
            "Data is open (where applicable) or fully controlled by users. However, users do not have the means to exploit it.",
        },
        {
          item: {
            value: 5,
            answer:
              "Data is open (where applicable) or fully controlled by users, who have also the necessary means to exploit it.",
          },
          name:
            "Data is open (where applicable) or fully controlled by users, who have also the necessary means to exploit it.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Is applying open data principles an option?",
        "If users hold their data, do they also have the means to exploit it?",
        "How easy is it for users to request and receive all information held about them?",
        "How easy is it for users to have their personal data deleted?",
        "How easy is it for users to request and transfer their data to another solution provider?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        creation: { dataOwnership: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { dataOwnership: { assessment: this.assessment } },
      });
    },
  },
};
</script>
