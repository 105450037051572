<template>
  <div class="availability">
    <stepper-progress stepNumber="1"></stepper-progress>
    <h2><span class="circle-counter">1</span>Availability</h2>
    <h3>Rationale</h3>
    <p>
      Availability refers to the presence of the necessary physical
      infrastructure, e.g. digital devices, mobile network coverage or broadband
      access (often also referred to as connectivity). However, it is important
      to note, that availability is not binary (being connected vs. remaining
      unconnected) but conveys more detailed gradations (e.g. stability of
      connectivity, data rates, etc.).
    </p>

    <guiding-question
      questionId="help-opportunity-availability"
      question="Do all people in the target region fulfil the solution’s technical
      requirements?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is physical access to indispensable infrastructure and thus
      the solution itself provided?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation/assessment"
      next="/opportunity/affordability"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";

export default {
  name: "Availability",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "opportunity.availability.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "opportunity.availability.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "Almost no one has unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
          },
          name:
            "Almost no one has unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
        },
        {
          item: {
            value: 2,
            answer:
              "Only the most advantaged people have unrestricted and relatively stable physical access to indispensable infrastructure, thus the solution itself.",
          },
          name:
            "Only the most advantaged people have unrestricted and relatively stable physical access to indispensable infrastructure, thus the solution itself.",
        },
        {
          item: {
            value: 3,
            answer:
              "Many people have unrestricted, but fluctuating physical access to indispensable infrastructure and thus the solution itself.",
          },
          name:
            "Many people have unrestricted, but fluctuating physical access to indispensable infrastructure and thus the solution itself.",
        },
        {
          item: {
            value: 4,
            answer:
              "Most people have unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
          },
          name:
            "Most people have unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
        },
        {
          item: {
            value: 5,
            answer:
              "Everyone has unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
          },
          name:
            "Everyone has unrestricted and relatively stable physical access to indispensable infrastructure and thus the solution itself.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution make use of existing digital devices (e.g. smartphones) or does its usage require additional, solution-specific devices?",
        "To which extent are multi-purpose digital devices (such as smartphones) available throughout the population?",
        "If access to physical infrastructure and/or connectivity is restricted, along which lines does stratification unfold (e.g. class, gender, age, urban vs. rural)? (How) Does this circumvent the solution’s intended impact?",
        "Are there additional social or cultural barriers restricting access for certain societal groups?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        opportunity: { availability: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        opportunity: { availability: { assessment: this.assessment } },
      });
    },
  },
};
</script>
