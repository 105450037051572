<template>
  <div class="step-creation">
    <h1><img src="../assets/img/bulb_blue.svg" alt="bulb" />Creation</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "StepCreation",
};
</script>
