<template>
  <div class="assessment-outcome">
    <stepper-progress stepNumber="6"></stepper-progress>
    <h2><span class="circle-counter">6</span>Assessment</h2>
    <p>
      Think about the outcome you would like to focus on and decide between the
      five criteria you reflected on before: Which one is the most important for
      your solution (e.g. an outcome you would like to focus on in your
      project)?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/outcome/conserving-natural-resource-base"
      next="/reflection"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";

export default {
  name: "AssessmentOutcome",
  components: { StepperButtons, StepperProgress },
  data: function () {
    return {
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.assessmentOutcome.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: "fulfillingBasicNeeds",
            answer: "1. Fulfilling Basic Needs",
          },
          name: "1. Fulfilling Basic Needs",
        },
        {
          item: {
            value: "generatingAdditionalIncome",
            answer: "2. Generation Additional Income",
          },
          name: "2. Generation Additional Income",
        },
        {
          item: {
            value: "enhancingPeoplesAgency",
            answer: "3. Enhancing People’s Agency",
          },
          name: "3. Enhancing People’s Agency",
        },
        {
          item: {
            value: "reducingVulnerability",
            answer: "4. Reducing Vulnerability",
          },
          name: "4. Reducing Vulnerability",
        },
        {
          item: {
            value: "conservingNaturalResourceBase",
            answer: "5. Conserving National Resource Base",
          },
          name: "5. Conserving National Resource Base",
        },
      ],
    };
  },
  watch: {
    assessment: function () {
      this.$store.commit("setFormData", {
        outcome: { assessmentOutcome: { assessment: this.assessment } },
      });
    },
  },
};
</script>
