<template>
  <div class="assessment-opportunity">
    <stepper-progress stepNumber="6"></stepper-progress>
    <h2><span class="circle-counter">6</span>Assessment</h2>
    <p>
      To which extent does the solution fulfil the 5 criteria addressed in the
      questions before?
    </p>
    <radar-chart chartId="chart-creation" :chartModel="chartData"></radar-chart>
    <stepper-buttons
      prev="/creation/accountability"
      next="/outcome"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import RadarChart from "@/components/RadarChart.vue";

export default {
  name: "AssessmentOpportunity",
  components: { StepperButtons, StepperProgress, RadarChart },
  data: function () {
    return {
      chart: null,
      chartData: [
        {
          topic: "Availability",
          score: this.getFormData("opportunity.availability.assessment.value"),
        },
        {
          topic: "Affordability",
          score: this.getFormData("opportunity.affordability.assessment.value"),
        },
        {
          topic: "Awareness",
          score: this.getFormData("opportunity.awareness.assessment.value"),
        },
        {
          topic: "Abilities",
          score: this.getFormData("opportunity.abilities.assessment.value"),
        },
        {
          topic: "Agency",
          score: this.getFormData("opportunity.agency.assessment.value"),
        },
      ],
    };
  },
  methods: {
    getFormData: function (path) {
      return this.$store.getters.getFormDataByPath(path);
    },
  },
};
</script>
