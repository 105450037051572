<template>
  <div class="step-result">
    <h1><img src="../assets/img/flag_blue.svg" alt="" />Results</h1>
    <p>
      Congratulations! You’ve completed the questionnaire. You can find and
      download all your results here.
    </p>
    <b-button
      variant="link"
      class="text-uppercase font-weight-bold d-print-none pl-0"
      @click="printWindow()"
      >Print or save your results as PDF</b-button
    >
    <hr />
    <overview></overview>
    <assessment-result></assessment-result>
    <stepper-buttons prev="/reflection" next=""></stepper-buttons>
  </div>
</template>

<script>
import AssessmentResult from "@/components/result/AssessmentResult";
import Overview from "@/components/result/Overview";
import StepperButtons from "@/components/StepperButtons.vue";

export default {
  name: "StepResult",
  components: {
    AssessmentResult,
    Overview,
    StepperButtons,
  },
  methods: {
    printWindow: function () {
      window.print();
    },
  },
};
</script>
