<template>
  <div class="affordability">
    <stepper-progress stepNumber="2"></stepper-progress>
    <h2><span class="circle-counter">2</span>Affordability</h2>
    <h3>Rationale</h3>
    <p>
      Even if the necessary physical infrastructure is available, its continuous
      and unrestricted use might not be affordable for all people (e.g. cost of
      hardware and electricity, mobile and data tariffs, etc.). Similar to
      availability, affordability is not binary as different levels of
      connectivity are also reflected in their respective prices.
    </p>
    <guiding-question
      questionId="help-opportunity-affordability"
      question="Can everyone afford the service?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is access to indispensable infrastructure and thus the
      solution itself affordable for everyone in the target population?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation/availability"
      next="/opportunity/awareness"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "Affordability",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "opportunity.affordability.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "opportunity.affordability.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "Almost no one in the target group can afford the solution.",
          },
          name: "Almost no one in the target group can afford the solution.",
        },
        {
          item: {
            value: 2,
            answer:
              "The most advantaged people in the target group can afford the solution.",
          },
          name:
            "The most advantaged people in the target group can afford the solution.",
        },
        {
          item: {
            value: 3,
            answer:
              "Many people can afford the solution, especially from middle-income groups.",
          },
          name:
            "Many people can afford the solution, especially from middle-income groups.",
        },
        {
          item: {
            value: 4,
            answer:
              "Most people can afford the solution, including many from disadvantaged contexts.",
          },
          name:
            "Most people can afford the solution, including many from disadvantaged contexts.",
        },
        {
          item: {
            value: 5,
            answer: "Everyone, even the poorest, can afford the solution.",
          },
          name: "Everyone, even the poorest, can afford the solution.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "How is the cost of the solution structured (e.g. single payment, monthly payment, pay per use ...)? How might this affect affordability?",
        "How does cost of use figure with respect to medium/median income, income of the bottom 10%, national poverty line etc.?",
        "Are budget-specific versions of the solution available? To which extent do users need to compromise on essential features when choosing such options?",
        "Are specific pro-poor business models/ mechanisms applied?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        opportunity: { affordability: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        opportunity: { affordability: { assessment: this.assessment } },
      });
    },
  },
};
</script>
