<template>
  <div class="step-need">
    <h1><img src="../assets/img/stats_blue.svg" alt="" /> Underlying need</h1>
    <b-form @submit="onSubmit">
      <b-form-group>
        <p>Who is the target user of the digital solution?</p>
        <b-form-textarea
          id="need-target"
          v-model="target"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <p>What particular user need does the solution address?</p>
        <b-form-textarea
          id="need-user"
          v-model="user"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <p>How does the solution tackle these needs?</p>
        <b-form-textarea
          id="need-tackle"
          v-model="tackle"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <stepper-buttons prev="/info" next="/creation"></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";

export default {
  name: "StepNeed",
  components: {
    StepperButtons,
  },
  data: function () {
    return {
      target: this.$store.getters.getFormDataByPath("need.target"),
      user: this.$store.getters.getFormDataByPath("need.user"),
      tackle: this.$store.getters.getFormDataByPath("need.tackle"),
    };
  },
  watch: {
    target: function () {
      this.$store.commit("setFormData", {
        need: { target: this.target },
      });
    },
    user: function () {
      this.$store.commit("setFormData", {
        need: { user: this.user },
      });
    },
    tackle: function () {
      this.$store.commit("setFormData", {
        need: { tackle: this.tackle },
      });
    },
  },
};
</script>
