<template>
  <div class="awareness">
    <stepper-progress stepNumber="3"></stepper-progress>
    <h2><span class="circle-counter">3</span>Awareness</h2>
    <h3>Rationale</h3>
    <p>
      Even if digital solutions are physically available and affordable, a lack
      of awareness regarding their existence, functions and relevance among the
      target group may constitute a third access barrier.
    </p>
    <guiding-question
      questionId="help-opportunity-awareness"
      question="Are the solution and its potential functions recognised within the target
      group?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is information about the solution and its problem-solving
      capacity accessible to everyone in the target population?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/opportunity/affordability"
      next="/opportunity/abilities"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";

export default {
  name: "Awareness",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "opportunity.awareness.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "opportunity.awareness.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "Information about the solution or the problem itself is hardly available to the target population.",
          },
          name:
            "Information about the solution or the problem itself is hardly available to the target population.",
        },
        {
          item: {
            value: 2,
            answer:
              "The most advantaged groups in the target population can access relevant information (high threshold).",
          },
          name:
            "The most advantaged groups in the target population can access relevant information (high threshold).",
        },
        {
          item: {
            value: 3,
            answer:
              "Many people, especially from middle-income group, can access the information.",
          },
          name:
            "Many people, especially from middle-income group, can access the information.",
        },
        {
          item: {
            value: 4,
            answer:
              "Information on both problem and solution are available to most people, including from disadvantaged contexts.",
          },
          name:
            "Information on both problem and solution are available to most people, including from disadvantaged contexts.",
        },
        {
          item: {
            value: 5,
            answer:
              "Information on both problem and solution are widely available and specifically designed for disadvantaged target groups (low threshold).",
          },
          name:
            "Information on both problem and solution are widely available and specifically designed for disadvantaged target groups (low threshold).",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "How well-developed is public awareness with respect to the problem the solution is designed to address?",
        "How do people get informed about the solution and its functions?",
        "Does the form of information presuppose any physical or cognitive skills (e.g. ability to read)?",
        "If so, is the campaign at risk of missing out on larger population segments? Which are those?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        opportunity: { awareness: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        opportunity: { awareness: { assessment: this.assessment } },
      });
    },
  },
};
</script>
