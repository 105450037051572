<template>
  <div class="step-outcome">
    <h1><img src="../assets/img/megaphone_blue.svg" alt="" />Outcome</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "StepOutcome",
  components: {},
};
</script>
