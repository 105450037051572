<template>
  <div class="conserving-natural-resource-base">
    <stepper-progress stepNumber="5"></stepper-progress>
    <h2>
      <span class="circle-counter">5</span>Conserving Natural Resource Base
    </h2>
    <h3>Rationale</h3>
    <p>
      In light of the poor’s reliance on the natural resource base of their
      immediate environment (especially in rural areas), a digital innovation’s
      ability to reduce environmental burdens and conserve rather than deplete
      resources makes for a fifth impact mechanism.
    </p>
    <guiding-question
      questionId="help-outcome-conserving-natural-resource-base"
      question="Does the solution unfold its impact through this fifth mechanism? If so, how?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution work to conserve or replenish natural
      resources critical to the target population’s well-being?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/outcome/reducing-vulnerability"
      next="/outcome/assessment"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "ConservingNaturalResourceBase",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "outcome.conservingNaturalResourceBase.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "outcome.conservingNaturalResourceBase.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution disregards matters of sustainability and further depletes finite resources.",
          },
          name:
            "The solution disregards matters of sustainability and further depletes finite resources.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution does not use finite resources but overuses renewable resources.",
          },
          name:
            "The solution does not use finite resources but overuses renewable resources.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution works within the self-restoration boundaries of all resources concerned.",
          },
          name:
            "The solution works within the self-restoration boundaries of all resources concerned.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution actively works to preserve the natural resource base.",
          },
          name:
            "The solution actively works to preserve the natural resource base.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution not only preserves, but also actively works to replenish natural resources.",
          },
          name:
            "The solution not only preserves, but also actively works to replenish natural resources.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution take matters of resource conservation into account?",
        "Does the solution work to protect or even restore a given natural resource?",
        "Which role does this resource play in the wider local context (e.g. ecologically, culturally, economically)?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        outcome: { conservingNaturalResourceBase: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: {
          conservingNaturalResourceBase: { assessment: this.assessment },
        },
      });
    },
  },
};
</script>
