<template>
  <div class="download">
    <h1>Downloads</h1>
    <p>
      If you would like to know more about the underlying study of the Pro Poor
      Digitalisation Tool you can download the GIZ Handbook "Beyond
      Connectivity” here! It includes practical examples and a thematic
      background on the interlinkages between poverty and inequality reduction
      and digitalisation.
    </p>
    <b-button
      variant="link"
      :href="`${publicPath}GIZ_Handbook_Pro_Poor_Digitalisation_Beyond_Connectivity.pdf`"
      ><img src="../assets/img/download_blue.svg" alt="download" class="pr-2" />
      Download manual</b-button
    >
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>