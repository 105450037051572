<template>
  <div class="assessment-creation">
    <stepper-progress stepNumber="6"></stepper-progress>
    <h2><span class="circle-counter">6</span>Assessment</h2>
    <p>
      To which extent does the solution fulfil the 5 criteria addressed in the
      questions before?
    </p>
    <radar-chart chartId="chart-creation" :chartModel="chartData"></radar-chart>
    <stepper-buttons
      prev="/creation/accountability"
      next="/opportunity"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import RadarChart from "@/components/RadarChart.vue";

export default {
  name: "AssessmentCreation",
  components: { StepperButtons, StepperProgress, RadarChart },
  data: function () {
    return {
      chart: null,
      chartData: [
        {
          topic: "Market Structure",
          score: this.getFormData("creation.marketStructure.assessment.value"),
        },
        {
          topic: "Capacity Building",
          score: this.getFormData("creation.capacityBuilding.assessment.value"),
        },
        {
          topic: "Data Ownership",
          score: this.getFormData("creation.dataOwnership.assessment.value"),
        },
        {
          topic: "Data Security",
          score: this.getFormData("creation.dataSecurity.assessment.value"),
        },
        {
          topic: "Accountabilty",
          score: this.getFormData("creation.accountability.assessment.value"),
        },
      ],
    };
  },
  methods: {
    getFormData: function (path) {
      return this.$store.getters.getFormDataByPath(path);
    },
  },
};
</script>
