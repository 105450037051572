<template>
  <div class="capacity-building">
    <stepper-progress stepNumber="2"></stepper-progress>
    <h2><span class="circle-counter">2</span>Capacity Building</h2>
    <h3>Rationale</h3>
    <p>
      Reducing existing inequalities requires opportunities for an ‘upgrading’
      of economic activities. Building domestic capital, hereby enabling ‘higher
      value-adding activities’ within developing countries, depicts an essential
      lever within the creation of digital innovations.
    </p>
    <guiding-question
      questionId="help-creation-capacity-building"
      question="Who is developing the solution? Is capacity building an integral part of
      the process?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      Does the solution allow for upward mobility of local production along
      global value chains?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation"
      next="/creation/data-ownership"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "CapacityBuilding",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "creation.capacityBuilding.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "creation.capacityBuilding.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution is entirely developed by and in the Global North.",
          },
          name:
            "The solution is entirely developed by and in the Global North.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution is developed in the Global North with some lower value-adding activities taking place in the local context.",
          },
          name:
            "The solution is developed in the Global North with some lower value-adding activities taking place in the local context.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution is developed in the Global North but in cooperation with local stakeholders. Some higher value-adding activities take place in the local context.",
          },
          name:
            "The solution is developed in the Global North but in cooperation with local stakeholders. Some higher value-adding activities take place in the local context.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution is a product of North-South co-creation. Most higher value-adding activities take place in the local context.",
          },
          name:
            "The solution is a product of North-South co-creation. Most higher value-adding activities take place in the local context.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution is entirely developed in the Global South. All higher value-adding activities take place in the local setting.",
          },
          name:
            "The solution is entirely developed in the Global South. All higher value-adding activities take place in the local setting.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Where are ‘higher value-adding activities’ currently taking place? [‘Higher value-adding activities’ in the context of the digital economy are for example the reation of code & content or processing and analysing information]",
        "Does the solution allow domestic firms to move from relatively low to higher value-adding activities by national/regional comparison?",
        "Does the solution allow for upward mobility of local production along global value chains?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        creation: { capacityBuilding: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { capacityBuilding: { assessment: this.assessment } },
      });
    },
  },
};
</script>
