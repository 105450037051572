import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    formData: {}
  },
  mutations: {
    setFormData(state, data) {
      state.formData = _.merge(state.formData, data);
    },
    setOverallAssessmentValue(state, data) {
      state.overallAssessmentValue = data;
    }
  },
  getters: {
    getFormDataByPath: state => data => {
      return _.get(state.formData, data, null);
    },
    getOverallAssessmentValue: (state, getters) => () => {
      let divisor = 0;
      let assessmentValueCreation = getters.getAssessmentValueCreation();
      let assessmentValueOpportunity = getters.getAssessmentValueOpportunity();
      let assessmentValueOutcome = getters.getAssessmentValueOutcome();

      if (assessmentValueCreation > 0) {
        divisor++;
      }
      if (assessmentValueOpportunity > 0) {
        divisor++;
      }
      if (assessmentValueOutcome > 0) {
        divisor++;
      }

      if (divisor == 0) {
        return 0;
      } else {
        return (
          parseFloat(
            assessmentValueCreation +
              assessmentValueOpportunity +
              assessmentValueOutcome
          ) / divisor
        );
      }
    },
    getAssessmentValueCreation: state => () => {
      let divisor = 0;
      let creationMarketStructure = _.get(
        state.formData,
        "creation.marketStructure.assessment.value",
        0
      );
      let creationCapacityBuilding = _.get(
        state.formData,
        "creation.capacityBuilding.assessment.value",
        0
      );
      let creationDataOwnership = _.get(
        state.formData,
        "creation.dataOwnership.assessment.value",
        0
      );
      let creationDataSecurity = _.get(
        state.formData,
        "creation.dataSecurity.assessment.value",
        0
      );
      let creationAccountability = _.get(
        state.formData,
        "creation.accountability.assessment.value",
        0
      );

      if (creationMarketStructure > 0) {
        divisor++;
      }
      if (creationCapacityBuilding > 0) {
        divisor++;
      }
      if (creationDataOwnership > 0) {
        divisor++;
      }
      if (creationDataSecurity > 0) {
        divisor++;
      }
      if (creationAccountability > 0) {
        divisor++;
      }

      if (divisor == 0) {
        return 0;
      } else {
        return (
          parseFloat(
            creationMarketStructure +
              creationCapacityBuilding +
              creationDataOwnership +
              creationDataSecurity +
              creationAccountability
          ) / divisor
        );
      }
    },
    getAssessmentValueOpportunity: state => () => {
      let divisor = 0;
      let opportunityAvailability = _.get(
        state.formData,
        "opportunity.availability.assessment.value",
        0
      );
      let opportunityAffordability = _.get(
        state.formData,
        "opportunity.affordability.assessment.value",
        0
      );
      let opportunityAwareness = _.get(
        state.formData,
        "opportunity.awareness.assessment.value",
        0
      );
      let opportunityAbilities = _.get(
        state.formData,
        "opportunity.abilities.assessment.value",
        0
      );
      let opportunityAgency = _.get(
        state.formData,
        "opportunity.agency.assessment.value",
        0
      );

      if (opportunityAvailability > 0) {
        divisor++;
      }
      if (opportunityAffordability > 0) {
        divisor++;
      }
      if (opportunityAwareness > 0) {
        divisor++;
      }
      if (opportunityAbilities > 0) {
        divisor++;
      }
      if (opportunityAgency > 0) {
        divisor++;
      }

      if (divisor == 0) {
        return 0;
      } else {
        return (
          parseFloat(
            opportunityAvailability +
              opportunityAffordability +
              opportunityAwareness +
              opportunityAbilities +
              opportunityAgency
          ) / divisor
        );
      }
    },
    getAssessmentValueOutcome: state => () => {
      let outcomeSubdimension = _.get(
        state.formData,
        "outcome.assessmentOutcome.assessment.value",
        ""
      );
      let outcomeSubdimensionValue = 0;
      if (outcomeSubdimension) {
        outcomeSubdimensionValue = _.get(
          state.formData,
          "outcome." + outcomeSubdimension + ".assessment.value",
          0
        );
        return outcomeSubdimensionValue;
      }
    }
  },
  actions: {},
  modules: {}
});
