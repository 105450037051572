<template>
  <div class="market-structure">
    <stepper-progress stepNumber="1"></stepper-progress>

    <h2><span class="circle-counter">1</span>Market Structure</h2>
    <h3>Rationale</h3>
    <p>
      Digital innovation tends to concentrate market power among a handful of
      platform providers, thereby exacerbating not only economic but also
      political imbalances.
    </p>
    <guiding-question
      questionId="help-creation-market-structure"
      question="Who is providing the solution and how is the solution provider positioned in the market?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is the solution the product of or basis for exploitation of
      market power?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/need"
      next="/creation/capacity-building"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "MarketStructure",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "creation.marketStructure.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "creation.marketStructure.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "The solution is provided by a global player monopoly/oligopoly.",
          },
          name:
            "The solution is provided by a global player monopoly/oligopoly.",
        },
        {
          item: {
            value: 2,
            answer:
              "The solution is provided by a global player operating in a competitive market environment.",
          },
          name:
            "The solution is provided by a global player operating in a competitive market environment.",
        },
        {
          item: {
            value: 3,
            answer:
              "The solution is provided by a local or regional player operating in a monopolistic/oligopolistic market environment.",
          },
          name:
            "The solution is provided by a local or regional player operating in a monopolistic/oligopolistic market environment.",
        },
        {
          item: {
            value: 4,
            answer:
              "The solution is provided by a local or regional player operating in a competitive market environment.",
          },
          name:
            "The solution is provided by a local or regional player operating in a competitive market environment.",
        },
        {
          item: {
            value: 5,
            answer:
              "The solution is provided by a local or regional player operating in a competitive market environment. Where applicable, development adheres to open sources principles.",
          },
          name:
            "The solution is provided by a local or regional player operating in a competitive market environment. Where applicable, development adheres to open sources principles.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Did the solution provider exploit an existing position of power in the process of solution development?",
        "Does the solution store or further enshrine market power in the hands of a single market player? Is that player of national, regional or global nature?",
        "Do other market players have the capacity to develop alternative solutions and thus disrupt the solution provider’s market power in a timely manner?",
        "To which extent does solution development adhere to open source principles?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        creation: { marketStructure: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { marketStructure: { assessment: this.assessment } },
      });
    },
  },
};
</script>
