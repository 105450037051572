<template>
  <div class="accountability">
    <stepper-progress stepNumber="5"></stepper-progress>
    <h2><span class="circle-counter">5</span>Accountability</h2>
    <h3>Rationale</h3>
    <p>
      Digital solutions have the potential to include and empower marginalised
      groups but often risk sidelining them even further. Hence, providers of
      digital solutions should be transparent and accountable to local politics
      and civil society.
    </p>
    <guiding-question
      questionId="help-creation-accountability"
      question="How is accountability and transparency to local politics and civil society
      ensured?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is the solution provider transparent and accountable to
      users, governments and other stakeholders?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation/data-security"
      next="/creation/assessment"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "Accountability",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "creation.accountability.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "creation.accountability.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer: "Almost no relevant information publicly available.",
          },
          name: "Almost no relevant information publicly available.",
        },
        {
          item: {
            value: 2,
            answer:
              "Users and stakeholders are informed about relevant decisions.",
          },
          name: "Users and stakeholders are informed about relevant decisions.",
        },
        {
          item: {
            value: 3,
            answer:
              " Users and stakeholders are consulted in decision-making processes.",
          },
          name:
            " Users and stakeholders are consulted in decision-making processes.",
        },
        {
          item: {
            value: 4,
            answer:
              "When making relevant decisions, the solution provider is actively seeking consensus with users and stakeholders.",
          },
          name:
            "When making relevant decisions, the solution provider is actively seeking consensus with users and stakeholders.",
        },
        {
          item: {
            value: 5,
            answer:
              "Relevant decisions are taken within a collaborative process involving users and stakeholders.",
          },
          name:
            "Relevant decisions are taken within a collaborative process involving users and stakeholders.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Is it possible for users, local governments and further stakeholders to assess the solution’s impact? If so, how?",
        "Do local governments have sufficient capacities to keep up with solution development in term of regulatory frameworks and legislation?",
        "Are users, local governments or civil society representatives able to hold the solution provider accountable? If so, through which mechanisms?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        creation: { accountability: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { accountability: { assessment: this.assessment } },
      });
    },
  },
};
</script>
