<template>
  <div class="data-security">
    <stepper-progress stepNumber="4"></stepper-progress>
    <h2><span class="circle-counter">4</span>Data Security</h2>
    <h3>Rationale</h3>
    <p>
      As the “poorest and most marginalised are also more likely to suffer
      disproportionally from some of the darker aspects” (Unwin 2019, p. 45) of
      digitalisation (e.g. cybercrime, online sexual harassment, etc.), data
      security is not an add-on to pro-poor digital solutions but must be an
      integral component of them.
    </p>
    <guiding-question
      questionId="help-creation-data-security"
      question="How is personal data stored and transmitted?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent does the solution take matters of data security into
      account and deploy pre-emptive measures?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/creation/data-ownership"
      next="/creation/accountability"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";

export default {
  name: "DataSecurity",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "creation.dataSecurity.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "creation.dataSecurity.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer:
              "Data security measures do not exist or show substantial gaps.",
          },
          name: "Data security measures do not exist or show substantial gaps.",
        },
        {
          item: {
            value: 2,
            answer:
              "Data security measures are fragmentary, but collection and processing of personal data are kept to a minimum.",
          },
          name:
            "Data security measures are fragmentary, but collection and processing of personal data are kept to a minimum.",
        },
        {
          item: {
            value: 3,
            answer:
              "Data security measures are adequate (reflecting users’ vulnerability) and based on an initial risk assessment and data management plan.",
          },
          name:
            "Data security measures are adequate (reflecting users’ vulnerability) and based on an initial risk assessment and data management plan.",
        },
        {
          item: {
            value: 4,
            answer:
              "Data security measures are fully General Data Protection Regulation (or equivalent) compliant.",
          },
          name:
            "Data security measures are fully General Data Protection Regulation (or equivalent) compliant.",
        },
        {
          item: {
            value: 5,
            answer:
              " Data security measures go beyond what is required by General Data Protection Regulation (or equivalent) standards.",
          },
          name:
            " Data security measures go beyond what is required by General Data Protection Regulation (or equivalent) standards.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Does the solution follow the principles of data minimisation (= adequate, relevant, limited to what is necessary)?",
        "Has a risk analysis regarding data security been carried out?",
        "Does a data management plan exist?",
        "Which safeguard mechanisms are in place?",
        "Are the measures in place appropriate given the level of the users’ vulnerability?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        creation: { dataSecurity: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        creation: { dataSecurity: { assessment: this.assessment } },
      });
    },
  },
};
</script>
