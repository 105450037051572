<template>
  <div class="result-item">
    <h4>
      <span v-if="stepCounter" class="circle-counter">{{ stepCounter }}</span
      >{{ title }}
    </h4>
    <p>
      {{ guidingQuestion }}
    </p>
    <p class="font-italic text-muted">
      {{ getFormData(guidingAnswer) }}
    </p>
    <p class="sub-lead">
      {{ assessmentQuestion }}
    </p>
    <p>
      <span class="font-italic"> {{ getFormData(assessmentAnswer) }} </span>
      <span
        v-if="getFormData(assessmentAnswerValue)"
        :class="assessmentAnswerScoreClass"
      >
        <strong>
          ({{ getFormData(assessmentAnswerValue) }}
          Points)
        </strong>
      </span>
      <span v-else class="font-italic"> -- </span>
    </p>
    <p v-if="additionalRessources">
      <literature-item :links="additionalRessources"></literature-item>
    </p>
  </div>
</template>

<script>
import LiteratureItem from "@/components/LiteratureItem.vue";

export default {
  name: "ResultItem",
  components: { LiteratureItem },
  props: {
    title: {
      type: String,
      required: false,
    },
    stepCounter: {
      type: String,
      required: false,
    },
    guidingQuestion: {
      type: String,
      required: false,
    },
    assessmentQuestion: {
      type: String,
      required: false,
    },
    formPath: {
      type: String,
      required: false,
    },
    additionalRessources: {
      type: Array,
      required: false,
    },
  },
  computed: {
    guidingAnswer: function () {
      return this.formPath + ".guiding";
    },
    assessmentAnswer: function () {
      return this.formPath + ".assessment.answer";
    },
    assessmentAnswerValue: function () {
      return this.formPath + ".assessment.value";
    },
    assessmentAnswerScoreClass: function () {
      let answerScore = this.getFormData(this.assessmentAnswerValue);
      switch (answerScore) {
        case 1:
          return "text-danger";
        case 2:
          return "text-danger";
        case 3:
          return "text-warning";
        case 4:
          return "text-info";
        case 5:
          return "text-info";
        default:
          return "";
      }
    },
  },
  methods: {
    getFormData: function (path) {
      return this.$store.getters.getFormDataByPath(path);
    },
  },
};
</script>