<template>
  <div class="abilities">
    <stepper-progress stepNumber="4"></stepper-progress>
    <h2><span class="circle-counter">4</span>Abilities</h2>
    <h3>Rationale</h3>
    <p>
      Effectively using digital innovations might presuppose a set of physical
      (e.g. being able to see or to hear) and cognitive (e.g. being able to
      read, having a certain level of digital literacy) abilities, resulting in
      unequal access based on the availability resp. unavailability of these
      skills.
    </p>
    <guiding-question
      questionId="help-opportunity-abilities"
      question="Does the solution account for the (physical & cognitive) capabilities of
      all potential users?"
      :subQuestions="subQuestions"
    ></guiding-question>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-textarea
          v-model="guiding"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <h3>Assessment</h3>
    <p class="form-radio-headline">
      To what extent is the solution usable, accessible and comprehensible to
      everyone in the target population?
    </p>
    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="assessment"
        :options="assessmentOptions"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        :aria-describedby="ariaDescribedby"
        stacked
      ></b-form-radio-group>
    </b-form-group>
    <stepper-buttons
      prev="/opportunity/awareness"
      next="/opportunity/agency"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";
import StepperProgress from "@/components/StepperProgress.vue";
import GuidingQuestion from "@/components/GuidingQuestion.vue";
export default {
  name: "Abilities",
  components: { StepperButtons, StepperProgress, GuidingQuestion },
  data: function () {
    return {
      guiding: this.$store.getters.getFormDataByPath(
        "opportunity.abilities.guiding"
      ),
      assessment: this.$store.getters.getFormDataByPath(
        "opportunity.abilities.assessment"
      ),
      assessmentOptions: [
        {
          item: {
            value: 1,
            answer: "Good consideration of accessibility issues.",
          },
          name: "Good consideration of accessibility issues.",
        },
        {
          item: {
            value: 2,
            answer: "Good consideration of usability issues.",
          },
          name: "Good consideration of usability issues.",
        },
        {
          item: {
            value: 3,
            answer:
              "Widely usable considering the given level of education and literacy in the target population.",
          },
          name:
            "Widely usable considering the given level of education and literacy in the target population.",
        },
        {
          item: {
            value: 4,
            answer:
              "Accessible in all languages relevant to target population.",
          },
          name: "Accessible in all languages relevant to target population.",
        },
        {
          item: {
            value: 5,
            answer:
              "Sensitive to social and cultural norms shared throughout the target population.",
          },
          name:
            "Sensitive to social and cultural norms shared throughout the target population.",
        },
        {
          item: {
            value: null,
            answer: "None of the answers given is applicable.",
          },
          name: "None of the answers given is applicable.",
        },
      ],
      subQuestions: [
        "Who is excluded due to a lack of certain physical or cognitive abilities? How could their inclusion be allowed for?",
        "Is user support provided? In which form?",
        "Do other market players have the capacity to develop alternative solutions and thus disrupt the solution provider’s market power in a timely manner?",
        "Are training and training resources for general digital skills available? To whom?",
      ],
    };
  },
  watch: {
    guiding: function () {
      this.$store.commit("setFormData", {
        opportunity: { abilities: { guiding: this.guiding } },
      });
    },
    assessment: function () {
      this.$store.commit("setFormData", {
        opportunity: { abilities: { assessment: this.assessment } },
      });
    },
  },
};
</script>
