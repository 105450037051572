<template>
  <div class="step-reflection">
    <h1>
      <img src="../assets/img/comment_blue.svg" alt="" />Concluding Reflection
    </h1>
    <b-form @submit="onSubmit">
      <b-form-group>
        <p>
          Which societal groups will particularly benefit from the solution?
        </p>
        <b-form-textarea
          id="reflection-benefit"
          v-model="benefit"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <p>
          Which societal groups will potentially lose out? Might the solution
          cause new inequalities?
        </p>
        <b-form-textarea
          id="reflection-lose"
          v-model="lose"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <p>
          Which hurdles need to be overcome to maximise the solution‘s impact?
        </p>
        <b-form-textarea
          id="reflection-hurdles"
          v-model="hurdles"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <p>Which concrete measures can be taken?</p>
        <b-form-textarea
          id="reflection-measures"
          v-model="measures"
          placeholder="Type something..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-form>
    <stepper-buttons
      prev="/outcome/assessment"
      next="/results"
    ></stepper-buttons>
  </div>
</template>

<script>
import StepperButtons from "@/components/StepperButtons.vue";

export default {
  name: "StepReflection",
  components: { StepperButtons },
  data: function () {
    return {
      benefit: this.$store.getters.getFormDataByPath("need.benefit"),
      lose: this.$store.getters.getFormDataByPath("need.lose"),
      hurdles: this.$store.getters.getFormDataByPath("need.hurdles"),
      measures: this.$store.getters.getFormDataByPath("need.measures"),
    };
  },
  watch: {
    benefit: function () {
      this.$store.commit("setFormData", {
        reflection: { benefit: this.benefit },
      });
    },
    lose: function () {
      this.$store.commit("setFormData", {
        reflection: { lose: this.lose },
      });
    },
    hurdles: function () {
      this.$store.commit("setFormData", {
        reflection: { hurdles: this.hurdles },
      });
    },
    measures: function () {
      this.$store.commit("setFormData", {
        reflection: { measures: this.measures },
      });
    },
  },
};
</script>
