<template>
  <div class="literature-item">
    <p><strong class="text-uppercase">Additional Ressources</strong></p>
    <ul class="list-unstyled">
      <li v-for="(link, index) in links" :key="index" class="mb-2">
        <a :href="link.url">{{ link.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LiteratureItem",
  props: {
    links: {
      type: Array,
      required: false,
    },
  },
};
</script>