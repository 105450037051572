<template>
  <div class="guiding-question">
    <h3>
      Guiding Question
      <img
        :id="questionId"
        class="heading-plus-icon"
        src="../assets/img/plus_blue.svg"
        alt="help"
      />
    </h3>
    <b-popover :target="questionId" triggers="hover" placement="right">
      <!-- <template #title>Auxiliary Sub-Questions</template> -->
      <p><strong>Auxiliary Sub-Questions</strong></p>
      <p v-for="(subQuestion, index) in subQuestions" :key="index">
        {{ subQuestion }}
      </p>
    </b-popover>
    <p>
      {{ question }}
    </p>
  </div>
</template>

<script>
export default {
  name: "GuidingQuestion",
  props: {
    questionId: {
      type: String,
      required: false,
    },
    question: {
      type: String,
      required: false,
    },
    subQuestions: {
      type: Array,
      required: false,
    },
  },
};
</script>