<template>
  <div class="home">
    <h1>Welcome to the pro poor digitalisation tool</h1>
    <p>
      You are a policy maker, development actor or innovator? You are interested
      in digital solutions that can shape and address poverty and inequality?
      You want to assess your own digital solution, a solution you have heard of
      or a technology field based on their potential for pro-poor developmental
      impact? Then you are in the right place!
    </p>
    <p>
      The Pro-Poor Digitalisation Tool (PPDT) serves as a hands-on tool to
      assess the potential of digital technologies and services for effectively
      tackling different dimensions of poverty and inequality. This tool guides
      you through a step-by-step analysis and assessment of any digital solution
      along three identified dimensions that have a specific pro-poor impact and
      can ensure to leave no one behind in digital development. The PPDT will
      also provide an overview of your analysis at the end and offer additional
      information and resources on how to shape and evaluate your solution to
      make sure no one is left behind, and your innovation has the best possible
      pro-poor impact.
    </p>
    <p>
      There is no right or wrong in the use of the PPDT. As long as it opens up
      new perspectives and sparks fresh ideas, you are on the right track.
    </p>
    <p>
      If you are interested in more information on the scientific rational
      behind the tool or see examples on how to use the tool, feel free to
      <router-link to="/download"
        >download the full PDF version of the underlying study “GIZ Handbook
        Pro-Poor Digitalisation - Beyond Connectivity”</router-link
      >.
    </p>
    <p>
      If you have any questions, please do not hesitate to contact us any time
      at
      <a href="mailto:poverty-inequality@giz.de">poverty-inequality@giz.de</a>.
    </p>
    <p>
      To get started, just enter the name of the digital solution below and dive
      right into it!
    </p>
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-input
          type="text"
          placeholder="Name"
          v-model="name"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <stepper-buttons next="/info"></stepper-buttons>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import StepperButtons from "@/components/StepperButtons.vue";

export default {
  name: "Home",
  components: { StepperButtons },
  data: function () {
    return {
      name: this.$store.getters.getFormDataByPath("home.name"),
    };
  },
  watch: {
    name: function () {
      this.$store.commit("setFormData", {
        home: { name: this.name },
      });
    },
  },
};
</script>
